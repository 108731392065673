<template>
  <div v-cloak>
    <transition name="fade" appear appear-active-class="fade-enter-active">
      <v-container v-if="apiLoaded">
        <h1>
          <span>Сотрудники</span>
          <div>
            <v-btn @click="$router.push('/edit-employee')">
              Новый сотрудник
            </v-btn>
          </div>
        </h1>
        <Register :config="regConfig" ref="registerComponent"></Register>
      </v-container>
    </transition>
  </div>
</template>


<style scoped>
.v-tabs:not(.v-tabs--vertical) .v-tab {
  letter-spacing: normal;
  padding: 0 10px;
}
</style>

<style lang="scss">
@import "../../styles/main.scss";
</style>


<script>
import Register from '../register/Register.vue';
import {loadDataToObject} from "@/modules/CommonUtils";
import {generateEmployeesRegisterConfig} from "@/modules/SharedFunctions";


export default {
  name: 'EmployeesComponent',
  props: {},
  components: {Register},
  data() {
    return {
      regConfig: {tabs: []},
      apiLoaded: false
    };
  },
  methods: {
    loadData: loadDataToObject
  },
  async beforeMount() {
    this.regConfig = await generateEmployeesRegisterConfig()
    this.apiLoaded = true
  },
}
</script>
